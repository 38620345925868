import request from "@/request/request-utils";

export default {
  shops: (data) =>
    request.postJson("/api/UniversalSeller/GetShopPageList", data),
  // 保存店铺
  saveShop: (data) => request.postJson("/api/UniversalSeller/SaveShop", data),
  // 商品列表
  products: (data) =>
    request.postJson("/api/UniversalSeller/GetProductPageList", data),
  // 保存商品
  saveProduct: (data) =>
    request.postData("/api/UniversalSeller/SaveProduct", data),
  // 获取商品信息
  productInfo: (params) =>
    request.getAction("/api/UniversalSeller/GetProduct", params),
  // 获取商品信息
  productCategory: (params) =>
    request.getAction("/api/UniversalSeller/GetProductCategorieId", params),
};
