<template>
  <div
    id="app"
    :class="
      $store.state.isPC === true
        ? 'pc-container'
        : $store.state.isPC === false
        ? 'tel-container'
        : ''
    "
  >
    <img
      v-if="$store.state.isPC === false"
      class="bgone"
      src="./assets/image/tel/bg/bgline1.png"
    />
    <img
      v-if="$store.state.isPC === false"
      class="bgtwo"
      src="./assets/image/tel/bg/bgline2.png"
    />
    <img
      v-if="$store.state.isPC === false"
      class="bgthree"
      src="./assets/image/tel/bg/bgline3.png"
    />

    <router-view v-if="$store.state.isPC === true" />
    <keep-alive
      v-else-if="$store.state.isPC === false"
      :include="['IndexPage']"
    >
      <router-view v-if="isCreated" />
    </keep-alive>

    <van-dialog
      title="完成转售"
      v-model="resaleConfirmShow"
      confirm-button-text="确认前往"
      @confirm="onConfirmResale"
    >
      <div class="dialog-tip">您发布的转售订单已支付成功，请前往确认!</div>
    </van-dialog>
  </div>
</template>

<script>
import { views } from "@/global/views";
export default {
  data() {
    return {
      isCreated: false,

      resaleConfirmShow: false,
    };
  },
  created() {
    document.title = "商家管理后台";

    this.$toast.setDefaultOptions({
      duration: 5000,
      closeOnClick: true,
      closeOnClickOverlay: true,
      overlay: true,
    });

    this.$store.commit("isPC", this.$utils.isPC());
    if (this.$store.state.isPC === true) {
      if (window.location.href.indexOf("/dbs/h5/") >= 0) {
        this.$router.push({
          path: "/dbs/home",
        });
      }
      // 通过/global/views.js文件设置路由，方便后期添加用户权限
      for (let i = 0; i < views.length; i++) {
        const view = views[i];
        this.$router.addRoute("MainPage", view);
      }
    } else if (this.$store.state.isPC === false) {
      if (
        window.location.href === window.location.origin + "/" ||
        window.location.href.indexOf("/dbs/") >= 0
      ) {
        this.$router.push({
          path: "/dbs/h5/index",
        });
      }

      let that = this;
      this.initData();
      this.$bus.$on("AccountLogin", () => {
        that.initData();
      });
    }

    // let that = this;
    // this.initWindowSize();
    // window.addEventListener(
    //   "resize",
    //   () => {
    //     that.initWindowSize();
    //   },
    //   false
    // );
  },
  methods: {
    async initData() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });

      // 获取最新网站版本号，如果版本号不一致，刷新界面重新拉取页面资源
      var websiteVersion = "";
      try {
        websiteVersion = await this.getWebsiteVersion();
      } catch (error) {
        console.log("WebsiteVersion", error);
      }
      var frontVersion = localStorage.getItem("FrontVersion");
      if (frontVersion !== websiteVersion) {
        localStorage.setItem("FrontVersion", websiteVersion);
        this.$router.go(0);
      }

      await this.initUserInfo();
      await this.initSystemDict();

      var accountToken = localStorage.getItem("AccountToken");
      if (!this.$utils.isEmptyText(accountToken)) {
        this.$bus.$emit("InitData");
      }

      this.isCreated = true;
      this.$toast.clear();
    },

    // 获取当前最新的网站版本
    getWebsiteVersion() {
      let that = this;
      return new Promise((resolve, reject) => {
        that.$api.common
          .networkVersion(window.location.origin + "/package.json")
          .then(
            (res) => {
              if (res.status === 200 && res.data) {
                resolve(res.data.version);
              }
              reject({});
            },
            (err) => {
              reject(err);
            }
          );
      });
    },

    // 初始化获取用户数据
    async initUserInfo() {
      try {
        let res = await this.$api.account.currentAccount();
        // 用户的微信付款码为空，设置用户的微信付款码状态为无效
        if (this.$utils.isEmptyText(res.data.paymentInfo.weiXinPaymentCode)) {
          res.data.paymentInfo.weiXinPaymentCodeInvalid = true;
        }
        // 用户的支付宝付款码为空，设置用户的支付宝付款码状态为无效
        if (this.$utils.isEmptyText(res.data.paymentInfo.zhiFuBaoPaymentCode)) {
          res.data.paymentInfo.zhiFuBaoPaymentCodeInvalid = true;
        }
        this.$store.commit("currentAccount", res.data);

        // if (
        //   res.data.paymentInfo.weiXinPaymentCodeInvalid ||
        //   res.data.paymentInfo.zhiFuBaoPaymentCodeInvalid
        // ) {
        //   setTimeout(() => {
        //     this.$toast.fail(
        //       `请上传${
        //         res.data.paymentInfo.weiXinPaymentCodeInvalid ? "微信" : ""
        //       }${
        //         res.data.paymentInfo.weiXinPaymentCodeInvalid &&
        //         res.data.paymentInfo.zhiFuBaoPaymentCodeInvalid
        //           ? "和"
        //           : ""
        //       }${
        //         res.data.paymentInfo.zhiFuBaoPaymentCodeInvalid ? "支付宝" : ""
        //       }收款码`
        //     );
        //   }, 500);
        // } else
        if (
          this.$store.state.currentAccount.balanceSellWaitDone &&
          this.$route.name !== "BalanceResale"
        ) {
          // 判断用户是否存在“已支付”的转售余额订单，存在的情况下，提醒用户确认支付
          this.resaleConfirmShow = true;
        }
        this.isFirstHome = false;
      } catch (error) {
        console.log("sellerShops", error);
      }
    },
    // 初始化获取字典
    async initSystemDict() {
      try {
        let resShop = await this.$api.dict.shop();
        this.$store.commit("sellerShops", resShop.data);
      } catch (error) {
        console.log("sellerShops", error);
      }
      // try {
      //   let resUnishop = await this.$api.dict.unishop();
      //   this.$store.commit("unisellerShops", resUnishop.data);
      // } catch (error) {
      //   console.log("unisellerShops", error);
      // }
      // try {
      //   let resUniproductCategory = await this.$api.dict.uniproductCategorie();
      //   this.$store.commit("uniproductCategory", resUniproductCategory.data);
      // } catch (error) {
      //   console.log("uniproductCategory", error);
      // }

      try {
        let resBase = await this.$api.dict.baseData();
        this.$store.commit("baseData", resBase.data);
      } catch (error) {
        console.log("baseData", error);
      }
    },

    // 前往确认完成转售
    onConfirmResale() {
      this.resaleConfirmShow = false;
      this.$router.push({
        path: "/dbs/h5/balance/resale",
      });
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0px;
}

#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 8;
  background-color: $app-color-bg;

  .bgone {
    width: 157px;
    height: 373px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
    pointer-events: none;
  }

  .bgtwo {
    width: 336px;
    height: 284px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 0;
    pointer-events: none;
  }

  .bgthree {
    width: 100%;
    position: absolute;
    right: 0px;
    top: 450px;
    z-index: 0;
    pointer-events: none;
  }
}

.color-warning {
  color: $app-color-warning !important;
}

.color-error {
  color: $app-color-error !important;
}

.color-success {
  color: $app-color-success !important;
}

.el-input__inner {
  color: #333 !important;
}

a {
  text-decoration: none;
}

a:hover {
  color: #777 !important;
}

img[src=""],
img:not([src]) {
  visibility: hidden;
}

.el-button {
  font-weight: normal !important;
}

#LRfloater0 {
  left: auto !important;
  right: 5px !important;
  top: auto !important;
  bottom: 100px !important;
}

.el-message {
  z-index: 3000 !important;
}

.el-image__preview {
  cursor: zoom-in !important;
}

.el-icon-info {
  cursor: pointer;
}

#LRfloater0 {
  display: none;
}

.loading-box {
  width: 100%;
  margin: 0px 0px 10px 0px;
  text-align: center;
  color: $app-tel-color-text-placeholder;
  font-size: 13px;

  p {
    margin: 0;
  }
}

.load-button {
  width: calc(100% - 20px);
  height: 42px;
  margin: 0px 10px 0px;
  border-radius: $app-tel-dimen-radius;
}

.color-primary-tel {
  color: $app-tel-color-primary !important;
}

.color-active-tel {
  color: $app-tel-color-active !important;
}

.color-warning-tel {
  color: $app-tel-color-warning !important;
}

.color-error-tel {
  color: $app-tel-color-error !important;
}

.color-grey-tel {
  color: $app-tel-color-text-grey !important;
}

.color-default-tel {
  color: $app-tel-color-text !important;
}

.detail-weight {
  font-weight: 500;
}

.popup-container {
  width: 100%;

  .popup-title {
    width: 100%;
    height: 44px;
    position: relative;

    .title {
      width: 100%;
      line-height: 44px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: $app-tel-color-title;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: -1;
    }
  }

  .popup-content {
    width: 100%;
    max-height: 80vh;
    overflow: hidden auto;
  }
}

.warning-content-tel {
  width: calc(100% - 50px);
  height: 36px;
  padding: 0px 15px;
  margin: 0px 10px;
  border-radius: $app-tel-dimen-radius;
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #fdf3f8 0px, #fed0e4 100%);

  .icon {
    width: 16px;
    height: 16px;
  }

  .text {
    width: calc(100% - 32px);
    margin-left: 15px;
    font-size: 13px;
    color: $app-tel-color-text;
    line-height: 15px;
  }
}

.dialog-tip {
  width: calc(100% - 32px);
  padding: 0px 16px 15px;
  font-size: 13px;
}

.help-tip {
  max-width: 230px;
  font-size: 13px;
  color: $app-tel-color-text;
  margin: 5px 10px;
  word-break: break-all;
  display: block;
}

.help-info-tel {
  width: 13px;
  height: 13px;
  font-size: 10px;
  border-radius: 50%;
  line-height: 13px;
  text-align: center;
  color: white;
  margin-left: 2px;
  background-color: $app-tel-color-text;
  font-weight: bold;
  font-style: italic;
}

.van-button--primary {
  background-color: $app-tel-color-primary;
  border: none;
  color: white;
}

.van-button--success {
  background-color: $app-tel-color-success;
  border: none;
  color: white;
}

.van-button--danger {
  background-color: $app-tel-color-error;
  border: none;
  color: white;
}

.van-button + .van-button {
  margin-left: 10px;
}

.van-cell::after {
  display: none;
}

.van-toast {
  width: auto;
  min-width: 88px;
  z-index: 999999999999 !important;
}

.van-dialog__confirm,
.van-dialog__confirm:active {
  color: $app-tel-color-primary;
  font-size: 14px;
}

.van-dialog__cancel {
  color: $app-tel-color-text-grey;
  font-size: 14px;
}

.van-button {
  line-height: 1;
  border-radius: $app-tel-dimen-radius;
}

.van-dialog {
  border-radius: $app-tel-dimen-radius;
}

.van-dialog__header {
  padding-top: 20px;
}

.van-popup {
  z-index: 30;
}

.van-popup--bottom.van-popup--round {
  border-radius: $app-tel-dimen-radius $app-tel-dimen-radius 0px 0px;
}

.van-radio__icon--checked .van-icon {
  background-color: $app-tel-color-primary;
  border-color: $app-tel-color-primary;
}

.van-checkbox__icon--checked .van-icon {
  background-color: $app-tel-color-primary;
  border-color: $app-tel-color-primary;
}

.van-radio__label {
  font-size: 14px;
}
</style>
