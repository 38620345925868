import request from "@/request/request-utils";

export default {
  // 主任务列表
  taskMain: (data) => request.postJson("/api/UniversalTask/GetTaskPages", data),
  // 子任务列表
  taskChild: (data) =>
    request.postJson("/api/UniversalTask/GetTaskChildPages", data),
  // 任务发布
  taskPublish: (data) => request.postData("/api/UniversalTask/AddTask", data),
  // 任务批量发布
  taskBatchPublish: (data) =>
    request.postJson("/api/UniversalTask/FastAddTask", data),
  // 任务快速编辑发布
  taskFastEditPublish: (url, data) => request.postData(url, data),
  // 任务详情
  taskDetail: (params) =>
    request.getAction("/api/UniversalTask/GetTask", params),
  // 任务批量返款
  taskBatchReturn: (data, params) =>
    request.postJsonAndParams(
      "/api/UniversalTask/SetTaskChildRefund",
      data,
      params
    ),
  // 任务批量取消
  taskBatchCancel: (data) =>
    request.postJson("/api/UniversalTask/SetTaskChildCancel", data),
  // 任务取消记录
  taskCancelLog: (data) =>
    request.postJson("/api/UniversalTask/GetTaskChildMemberCancelPages", data),
  // 任务评价
  taskEvaluate: (data) =>
    request.postJson("/api/UniversalTask/GetTaskChildEvaluatePages", data),
  // 设置任务评价
  setTaskEvaluate: (url, data) => request.postData(url, data),
  // 任务取消
  taskBack: (params) =>
    request.getAction("/api/UniversalTask/SetTaskChildBack", params),
  // 任务账单明细
  taskBill: (params) =>
    request.getAction("/api/UniversalTask/GetTaskChildBills", params),
  // 任务账单明细
  taskPayment: (params) =>
    request.getAction("/api/UniversalTask/GetTaskChildPaymentInfo", params),
  // 任务账单明细
  taskExcel: (data) =>
    request.postDataForFile("/api/UniversalTask/GetTaskChildExcel", data),
  // 设置收款码无效
  paymentInvalid: (params) =>
    request.getAction("/api/UniversalTask/SetTaskChildMemberPaymentCodeInvalid", params),
  // 店铺可用货返额度
  shopHFQuota: (params) =>
    request.getAction("/api/UniversalTask/GetShopHuoFanQuotaUsable", params),
};
