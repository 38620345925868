import account from "./account.js";
import dict from "./dict.js";
import file from "./file.js";
import seller from "./seller.js";
import task from "./task.js";
import balance from "./balance.js";
import common from "./common.js";
import help from "./help.js";
import uniseller from "./uniseller.js";
import unitask from "./unitask.js";

export default {
  account,
  dict,
  file,
  seller,
  task,
  balance,
  common,
  help,
  uniseller,
  unitask,
};
